/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
altai-cookie-bar .altai-cookie-bar-inner {
  display: block;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #19d1c6;
  padding: 10px 15px;
  opacity: 0.95;
  z-index: 1101;
  line-height: 150%;
  color: white;
  border-radius: 10px;
}
altai-cookie-bar .altai-cookie-bar-inner .cookiebartext {
  margin-bottom: 20px;
}
altai-cookie-bar .altai-cookie-bar-inner .cookiebarbuttons .comeos-button {
  color: #000;
  display: inline-block;
  background-color: transparent;
  padding: 10px;
  min-height: 40px;
  max-height: 80px;
  border-radius: 40px;
  border: 2px solid #fff;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  transition: background-color 100ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 20px;
  margin-bottom: 20px;
  color: white;
}
